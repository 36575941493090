import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Descope, useSession } from '@descope/react-sdk';
import { addUserToNotion, getUserPermissions } from '../services/notionService';

/**
 * Login component for user authentication using Descope
 * @returns {JSX.Element} Login form
 */
const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSession();

  // Redirect to home if already authenticated
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  // Handle successful authentication
  const onSuccess = async (e) => {
    console.log('onSuccess', e);
    try {
      const user = e.detail.user;
      console.log('User data:', user);

      // Check if this is a sign up (new user)
      if (e.detail.firstSeen) {
        console.log('New user signed up:', user);
        await addUserToNotion(user);
      }

      // Fetch user permissions from Notion
      const userPermissions = await getUserPermissions(user.email);
      console.log('User permissions from Notion:', {
        name: userPermissions.name,
        email: userPermissions.email,
        accessLevel: userPermissions.accessLevel,
        allowedDueDiligences: userPermissions.allowedDueDiligences,
        allowedPortfolio: userPermissions.allowedPortfolio
      });

      // Store permissions in localStorage
      localStorage.setItem('userPermissions', JSON.stringify({
        accessLevel: userPermissions.accessLevel,
        allowedDueDiligences: userPermissions.allowedDueDiligences,
        allowedPortfolio: userPermissions.allowedPortfolio
      }));

      // Log stored permissions
      console.log('Stored permissions in localStorage:',
        JSON.parse(localStorage.getItem('userPermissions'))
      );

      navigate('/');
    } catch (error) {
      console.error('Error handling user login:', error);
      // Still navigate to home even if permission fetching fails
      navigate('/');
    }
  };

  // Handle authentication failure
  const onError = (e) => {
    console.error('Authentication error:', e);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-forest-50 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-xl px-4 sm:px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-lg">
        <h3 className="text-xl sm:text-2xl font-bold text-center text-forest-800 mb-6">Login to Your Account</h3>
        <Descope
          flowId="sign-up-or-in"
          onSuccess={ onSuccess }
          onError={ onError }
        // theme={{
        //   root: {
        //     colorPrimary: '#047857', // forest-600
        //     colorBackground: '#ffffff',
        //     borderRadius: '0.5rem',
        //   }
        // }}
        />
      </div>
    </div>
  );
};

export default Login;