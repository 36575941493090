import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../../../styles/index.js';

const Container = styled.div`
  background: ${theme.common.glassmorphism.background};
  backdrop-filter: ${theme.common.glassmorphism.backdropFilter};
  box-shadow: ${theme.common.glassmorphism.boxShadow};
  padding: ${theme.common.spacing.xl};
  border-radius: 8px;
  margin-top: ${theme.common.spacing.xl};
  overflow: hidden;
`;

const Header = styled.div`
  margin-bottom: ${theme.common.spacing.lg};
`;

const Title = styled.h2`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.xs};
`;

const Subtitle = styled.p`
  color: ${theme.colors.text.muted};
  font-size: 0.875rem;
`;

const TimelineContent = styled.div`
  position: relative;
  padding-top: ${theme.common.spacing.md};
`;

const TimelineLine = styled.div`
  position: absolute;
  left: 0;
  width: 0.125rem;
  height: 100%;
  background: linear-gradient(to bottom, ${theme.colors.primary}, ${theme.colors.secondary});
`;

const YearContainer = styled.div`
  margin-left: 2rem;
  margin-bottom: 3rem;
  position: relative;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const YearHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.common.spacing.md};
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`;

const ToggleIcon = styled.span`
  transform: rotate(${props => props.isOpen ? '90deg' : '0deg'});
  transition: transform 0.2s ease;
  display: inline-block;
`;

const YearDot = styled.div`
  position: absolute;
  left: -2.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${theme.colors.primary};
  border: 3px solid ${theme.colors.background.main};
`;

const YearLabel = styled.h3`
  font-family: ${theme.fonts.heading};
  font-size: 1.25rem;
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.md};
`;

const ScrollContainer = styled.div`
  overflow-x: auto;
  margin: 0 -${theme.common.spacing.xl};
  padding: 0 ${theme.common.spacing.xl};
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const CategoryGrid = styled.div`
  display: grid;
  gap: ${theme.common.spacing.md};
  min-width: 768px; // Minimum width to prevent squishing
`;

const CategoryCard = styled.div`
  background: ${theme.colors.background.subtle};
  border-radius: 8px;
  padding: ${theme.common.spacing.md};
`;

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.common.spacing.sm};
`;

const CategoryLabel = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.common.spacing.sm};
  font-weight: 600;
  color: ${theme.colors.text.dark};
  
  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background-color: ${props => props.color};
  }
`;

const Status = styled.span`
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.75rem;
  background: ${props => `${props.color}20` || `${theme.colors.text.muted}20`};
  color: ${props => props.color || theme.colors.text.muted};
  display: inline-block;
`;

const DetailsList = styled.div`
  display: grid;
  gap: ${theme.common.spacing.sm};
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${theme.common.spacing.sm};
  background: ${theme.colors.background.main}80;
  border-radius: 4px;
  font-size: 0.875rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: ${theme.common.spacing.sm};
  }
`;

const DetailValue = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.common.spacing.sm};
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

const Timeline = ({ years, categories }) => {
  const [openYears, setOpenYears] = useState({});

  const toggleYear = (year) => {
    setOpenYears(prev => ({
      ...prev,
      [year]: !prev[year]
    }));
  };

  const getTimelineData = (category, year) => {
    switch (category.label) {
      case 'Harvesting':
        return category.metrics.operations.map(op => ({
          label: op.name,
          value: op.volume,
          status: op.status,
          completion: op.completion
        }));
      case 'Funding':
        return category.metrics.grants.map(grant => ({
          label: grant.name,
          value: grant.amount,
          status: grant.status
        }));
      case 'Road Construction':
        return category.metrics.segments.map(segment => ({
          label: segment.name,
          value: segment.length,
          status: segment.status,
          completion: segment.completion
        }));
      case 'Silviculture':
        return category.metrics.treatments.map(treatment => ({
          label: treatment.name,
          value: treatment.area,
          status: treatment.status
        }));
      case 'Planting':
        return category.metrics.species.map(species => ({
          label: species.name,
          value: species.quantity,
          status: species.status
        }));
      case 'Management':
        return category.metrics.components.map(component => ({
          label: component.name,
          value: component.amount,
          status: component.type
        }));
      default:
        return [];
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'complete':
      case 'completed':
        return theme.colors.status.success;
      case 'in progress':
      case 'progress':
        return theme.colors.status.warning;
      case 'planned':
      case 'scheduled':
        return theme.colors.secondary;
      case 'pending':
        return theme.colors.status.warning;
      case 'secured':
        return theme.colors.status.success;
      case 'active':
      case 'ongoing':
        return theme.colors.primary;
      case 'fixed':
      case 'variable':
        return theme.colors.secondary;
      default:
        return theme.colors.text.muted;
    }
  };

  return (
    <Container>
      <Header>
        <Title>Implementation Timeline</Title>
        <Subtitle>Detailed progress and milestones by year</Subtitle>
      </Header>
      <ScrollContainer>
        <TimelineContent>
          <TimelineLine />
          { years.map((year) => (
            <YearContainer key={ year }>
              <YearDot />
              <YearHeader onClick={ () => toggleYear(year) }>
                <YearLabel>{ year }</YearLabel>
                <ToggleIcon isOpen={ openYears[year] }>▶</ToggleIcon>
              </YearHeader>
              { openYears[year] && (
                <CategoryGrid>
                  { Object.values(categories).map((category) => {
                    const timelineItems = getTimelineData(category, year);
                    if (!timelineItems.length) return null;

                    return (
                      <CategoryCard key={ category.label }>
                        <CategoryHeader>
                          <CategoryLabel color={ category.color }>
                            { category.label }
                          </CategoryLabel>
                          <Status color={ category.color }>
                            Q{ (years.indexOf(year) % 4) + 1 }
                          </Status>
                        </CategoryHeader>
                        <DetailsList>
                          { timelineItems.map((item, idx) => (
                            <DetailItem key={ idx }>
                              <span>{ item.label }</span>
                              <DetailValue>
                                <span>{ item.value }</span>
                                <Status color={ getStatusColor(item.status) }>
                                  { item.status?.charAt(0).toUpperCase() + item.status?.slice(1).toLowerCase() }
                                </Status>
                                { item.completion && (
                                  <Status color={ getStatusColor(item.status) }>
                                    { item.completion }
                                  </Status>
                                ) }
                              </DetailValue>
                            </DetailItem>
                          )) }
                        </DetailsList>
                      </CategoryCard>
                    );
                  }) }
                </CategoryGrid>
              ) }
            </YearContainer>
          )) }
        </TimelineContent>
      </ScrollContainer>
    </Container>
  );
};

export default Timeline;
