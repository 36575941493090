import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../styles/index.js';
import { years, categories } from './data/categories.js';
import MetricCard from './components/MetricCard';
import CashflowChart from './components/CashflowChart';
import BudgetDistribution from './components/BudgetDistribution';
import Timeline from './components/Timeline';
import SummaryTable from './components/SummaryTable';
import MapView from './components/MapView';

const DashboardContainer = styled.div`
  padding: ${theme.common.spacing.lg};
  padding-top: ${theme.common.timeline.container.topSpacing};
  background: transparent;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  margin-bottom: ${theme.common.spacing.xl};
  padding-bottom: ${theme.common.spacing.md};
  border-bottom: 1px solid ${theme.colors.border.light};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TitleSection = styled.div`
  flex: 1;
`;

const PrintButton = styled.button`
  background: ${theme.colors.primary};
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: ${theme.fonts.body};
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: ${theme.colors.primaryDark};
  }
`;

const PageTitle = styled.h1`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.sm};
  font-size: 1.8rem;
`;

const Subtitle = styled.p`
  color: ${theme.colors.text.muted};
  font-family: ${theme.fonts.body};
`;

const MetricsGrid = styled.div`
  display: grid;
  gap: ${theme.common.spacing.md};
  margin-bottom: ${theme.common.spacing.xl};
  width: 100%;

  /* Mobile: 1 card per row */
  grid-template-columns: 1fr;
  
  /* Tablet: 2 cards per row */
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  /* Desktop: 3 cards per row */
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ChartGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${theme.common.spacing.xl};
  margin-bottom: ${theme.common.spacing.xl};
  
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const CashflowChartContainer = styled.div`
  width: 65%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const BudgetDistributionContainer = styled.div`
  width: 35%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ManagementPlan = () => {
  const metrics = Object.entries(categories).map(([key, category]) => ({
    category: category.label,
    color: category.color,
    metrics: category.metrics
  }));

  const handlePrint = () => {
    window.print();
  };

  return (
    <DashboardContainer>
      <Header>
        <TitleSection>
          <PageTitle>Forest Management Plan | Test Forest Holding</PageTitle>
          <Subtitle>
            Comprehensive overview of activities, investments, and projected returns (2024-2028)
          </Subtitle>
        </TitleSection>
        <PrintButton onClick={ handlePrint }>
          <span>🖨️</span> Print
        </PrintButton>
      </Header>

      <MetricsGrid>
        {metrics.map((metric, index) => (
          <MetricCard
            key={index}
            category={metric.category}
            color={metric.color}
            metrics={metric.metrics}
          />
        ))}
      </MetricsGrid>

      <ChartGrid>
        <CashflowChartContainer>
          <CashflowChart years={years} categories={categories} />
        </CashflowChartContainer>
        <BudgetDistributionContainer>
          <BudgetDistribution categories={categories} />
        </BudgetDistributionContainer>
      </ChartGrid>

      <MapView />

      <SummaryTable />

      <Timeline years={years} categories={categories} />
    </DashboardContainer>
  );
};

export default ManagementPlan;
