import React, { useState } from 'react';
import { Alert, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

const AlertPopUp = ({ 
  message = "System maintenance in progress. Some features may be temporarily unavailable.",
  severity = "warning",
  persistent = false,
  onClose
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  if (!open) return null;

  return (
    <Box 
      sx={{
        position: 'relative',
        width: '100%',
        '& .MuiAlert-root': {
          alignItems: 'center'
        }
      }}
    >
      <Alert
        severity={severity}
        icon={<InfoIcon />}
        action={
          !persistent && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
      >
        {message}
      </Alert>
    </Box>
  );
};

export default AlertPopUp;
