/**
 * AirTable Service
 * 
 * This module provides functions to interact with the AirTable API through our backend server.
 * It includes error handling, logging for debugging purposes, and dedicated parsing functions.
 */

export const getApiUrl = (path) => {
  // Return only the path for relative URL
  return path;
};

/**
 * Queries the AirTable base through our backend server
 * @param {string} baseId - The ID of the AirTable base
 * @param {string} tableId - The ID or name of the table
 * @param {string} [filter] - The filter to apply to the query (optional)
 * @returns {Promise<Array>} A promise that resolves to an array of records
 * @throws {Error} If there's any problem fetching the data
 */
export const queryAirtableBase = async (baseId, tableId, filter) => {
  try {
    console.log('Querying AirTable base...');
    console.log('Base ID:', baseId);
    console.log('Table ID:', tableId);
    console.log('Filter:', filter);
    console.log('API URL:', getApiUrl('/api/query-airtable'));

    const response = await fetch(getApiUrl('/api/query-airtable'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ baseId, tableId, filter }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    const data = await response.json();
    const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '{}');

    if (userPermissions.accessLevel !== 'Selected Access') {
      return data;
    }

    const matchData = data.records.filter(record => {
      const allowedPortfolios = Array.isArray(userPermissions.allowedPortfolio)
        ? userPermissions.allowedPortfolio
        : [userPermissions.allowedPortfolio];

      // Split the LandownerName field by comma and trim each name
      const recordLandowners = record.fields.LandownerName?.split(',').map(name => name.trim()) || [];

      // Check if any of the record's landowners match any of the allowed portfolios
      return recordLandowners.some(landowner =>
        allowedPortfolios.some(portfolio =>
          landowner.toLowerCase() === portfolio?.toLowerCase()
        )
      );
    });

    return { records: matchData };
  } catch (error) {
    console.error('Error querying AirTable base:', error);
    throw error;
  }
};

/**
 * Retrieves a specific record from AirTable through our backend server
 * @param {string} baseId - The ID of the AirTable base
 * @param {string} tableId - The ID or name of the table
 * @param {string} recordId - The ID of the record to retrieve
 * @returns {Promise<Object>} A promise that resolves to the record data
 * @throws {Error} If there's any problem fetching the data
 */
export const retrieveAirtableRecord = async (baseId, tableId, recordId) => {
  try {
    console.log('Retrieving AirTable record...');
    console.log('Base ID:', baseId);
    console.log('Table ID:', tableId);
    console.log('Record ID:', recordId);

    const response = await fetch(getApiUrl(`/api/retrieve-airtable-record`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ baseId, tableId, recordId }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    const data = await response.json();
    console.log('Received record data from AirTable:', data);
    return data;
  } catch (error) {
    console.error('Error retrieving AirTable record:', error);
    throw error;
  }
};

/**
 * Parses records from the AirTable response
 * @param {Array} data - The raw data received from AirTable
 * @returns {Array} An array of parsed record objects
 */
export const parseAirtableRecords = (data) => {
  if (!data || !data.records) {
    console.warn('Invalid data format received:', data);
    return [];
  }

  const parsed = data.records.map(record => ({
    id: record.id,
    ...record.fields
  }));
  return parsed;
};

/**
 * Fetches and parses records from an AirTable base
 * @param {string} baseId - The ID of the AirTable base
 * @param {string} tableId - The ID or name of the table
 * @param {string} [filter] - Optional Airtable formula string (e.g., "{Published} = TRUE()")
 * @returns {Promise<Array>} A promise that resolves to an array of parsed records
 */
export const fetchAndParseAirtableRecords = async (baseId, tableId, filter) => {
  console.log('Filter being sent to Airtable:', filter);

  const rawData = await queryAirtableBase(baseId, tableId, filter);
  return parseAirtableRecords(rawData);
};
