import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSession } from '@descope/react-sdk';
import LoadingOverlay from './BaseComponents/LoadingOverlay';

/**
 * ProtectedRoute component to restrict access to authenticated users
 * @returns {JSX.Element} Protected route or redirect to login
 */
const ProtectedRoute = () => {
  const { isAuthenticated, isLoading } = useSession();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    // Add a small delay to ensure session is properly loaded
    const timer = setTimeout(() => {
      setIsCheckingAuth(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading || isCheckingAuth) {
    return <LoadingOverlay />;
  }

  // If the user is authenticated, render the child routes
  // Otherwise, redirect to the login page
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;