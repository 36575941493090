import React, { useRef, useEffect, useState } from 'react';
import { theme } from '../../styles';

const SwitchSelector = ({
  options = [],
  activeOption,
  onSwitch,
  size = 'medium',
}) => {
  const [activeButtonRect, setActiveButtonRect] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const buttonsRef = useRef({});
  const currentSize = theme.sizes[size] || theme.sizes.medium;

  useEffect(() => {
    // Initial resize handler
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      // Update active button rect on resize
      updateActiveButtonRect();
    };

    // Initial calculation
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty dependency array for initial mount only

  // Separate function to update active button rect
  const updateActiveButtonRect = () => {
    const activeButton = buttonsRef.current[activeOption];
    if (activeButton) {
      const rect = activeButton.getBoundingClientRect();
      const parentRect = activeButton.parentElement.getBoundingClientRect();
      setActiveButtonRect({
        width: rect.width,
        height: rect.height,
        left: rect.left - parentRect.left,
      });
    }
  };

  useEffect(() => {
    updateActiveButtonRect();
  }, [activeOption, isMobile]); // Add isMobile as dependency

  return (
    <div style={ {
      ...theme.common.glassmorphism,
      padding: currentSize.padding,
      borderRadius: theme.common.borderRadius,
      display: 'inline-flex',
      gap: '4px',
      position: 'relative',
      width: isMobile ? '100%' : 'auto',
      justifyContent: isMobile ? 'space-between' : 'flex-start',
      flexWrap: 'nowrap',
    } }>
      { activeButtonRect && (
        <div
          style={ {
            position: 'absolute',
            width: activeButtonRect.width,
            height: activeButtonRect.height,
            left: activeButtonRect.left,
            borderRadius: theme.common.borderRadius,
            background: theme.colors.primary,
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            zIndex: 0,
          } }
        />
      ) }

      { options.map((option) => (
        <button
          key={ option.value }
          ref={ el => buttonsRef.current[option.value] = el }
          onClick={ () => onSwitch(option.value) }
          style={ {
            padding: currentSize.buttonPadding,
            height: currentSize.height,
            borderRadius: theme.common.borderRadius,
            border: 'none',
            background: 'transparent',
            color: activeOption === option.value ? theme.colors.text.light : theme.colors.text.primary,
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '6px',
            fontSize: isMobile ? '0.8rem' : currentSize.fontSize,
            position: 'relative',
            zIndex: 1,
            boxSizing: 'border-box',
            lineHeight: '1',
            flex: isMobile ? '1' : 'initial',
            minWidth: isMobile ? '0' : 'auto',
            maxWidth: isMobile ? '33%' : 'none',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          } }
        >
          { React.createElement(option.icon, {
            style: {
              fontSize: isMobile ? '1rem' : currentSize.iconSize,
              transition: 'all 0.3s ease',
            }
          }) }
          <span style={ {
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          } }>
            { option.label }
          </span>
        </button>
      )) }
    </div>
  );
};

export default SwitchSelector; 