import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaTree, FaSearchPlus, FaHandshake, FaChartLine, FaCheckCircle, FaUserTie, FaChartBar, FaShieldAlt, 
  FaLeaf, FaChartPie, FaGlobeAmericas, FaUsers, FaCaretUp, FaQuoteRight } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useSession } from '@descope/react-sdk';
import { theme } from '../styles/index';
import OpportunityCard from '../components/OpportunityCard';
import '../styles/index.css';

const Section = ({ children, className = '' }) => (
  <section className={`py-16 px-4 ${className}`}>
    <div className="max-w-7xl mx-auto">
      {children}
    </div>
  </section>
);

const CapabilityCard = ({ icon: Icon, title, description, index }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
  >
    <motion.div 
      className="p-8 bg-background-light rounded-xl shadow-lg flex flex-col items-center h-full relative overflow-hidden group" 
      style={{ backgroundColor: theme.colors.background.light }}
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <motion.div 
        className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-5 transition-opacity duration-300"
        style={{ backgroundColor: theme.colors.primary }}
      />
      <motion.div 
        className="mb-6 text-primary relative"
        style={{ color: theme.colors.primary }}
        whileHover={{ scale: 1.1 }}
        transition={{ type: "spring", stiffness: 400 }}
      >
        <Icon size={42} />
      </motion.div>
      <h3 className="text-xl font-bold mb-4 text-center relative">{title}</h3>
      <p className="text-text-primary text-center relative" style={{ color: theme.colors.text.primary }}>{description}</p>
    </motion.div>
  </motion.div>
);

const BenefitCard = ({ icon: Icon, title, description }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.9 }}
    whileInView={{ opacity: 1, scale: 1 }}
    viewport={{ once: true }}
    whileHover={{ y: -5 }}
    className="bg-white p-6 rounded-lg shadow-md"
  >
    <div className="flex items-start space-x-4">
      <div className="text-primary" style={{ color: theme.colors.primary }}>
        <Icon size={24} />
      </div>
      <div>
        <h4 className="font-semibold mb-2">{title}</h4>
        <p className="text-sm text-text-primary" style={{ color: theme.colors.text.primary }}>{description}</p>
      </div>
    </div>
  </motion.div>
);

const Capabilities = () => {
  const capabilities = [
    {
      icon: FaTree,
      title: "Find Forest Investment Opportunities",
      description: "Discover and invest in lucrative forest opportunities tailored to your investment goals."
    },
    {
      icon: FaSearchPlus,
      title: "Get Tailored, Rapid Forest Due Diligences",
      description: "Receive comprehensive and swift due diligence reports to make informed decisions."
    },
    {
      icon: FaHandshake,
      title: "Sell Your Forest to Sustainable Investors",
      description: "Connect with investors who value sustainability and get the best value for your forest."
    },
    {
      icon: FaChartLine,
      title: "World-Class Forest Portfolio Reporting",
      description: "Access detailed reports and insights to manage and optimize your forest portfolio."
    }
  ];

  return (
    <Section className="bg-background-subtle" style={{ backgroundColor: theme.colors.background.subtle }}>
      <motion.div 
        className="max-w-8xl mx-auto mb-12 text-center"
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-4xl font-bold text-text-dark mb-4" style={{ color: theme.colors.text.dark }}>Our Capabilities</h2>
        <p className="text-lg text-text-primary" style={{ color: theme.colors.text.primary }}>
          Comprehensive tools and services for forest investment management
        </p>
      </motion.div>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {capabilities.map((capability, index) => (
          <CapabilityCard key={index} {...capability} index={index} />
        ))}
      </div>
    </Section>
  );
};

const ReportingCard = ({ category, icon: Icon, metrics, trend }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="p-6 bg-background-light rounded-xl shadow-lg relative overflow-hidden group"
    style={{ backgroundColor: theme.colors.background.light }}
    whileHover={{ y: -5 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <motion.div 
      className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-5 transition-opacity duration-300"
      style={{ backgroundColor: theme.colors.primary }}
    />
    <div className="flex items-center justify-between mb-4">
      <div className="text-primary" style={{ color: theme.colors.primary }}>
        <Icon size={32} />
      </div>
      <div className="flex items-center text-green-500">
        <FaCaretUp />
        <span className="text-sm font-semibold">{trend}%</span>
      </div>
    </div>
    <h3 className="text-xl font-bold mb-3">{category} Reporting</h3>
    <div className="space-y-2">
      {metrics.map((metric, index) => (
        <div key={index} className="flex items-center justify-between text-sm">
          <span className="text-text-primary" style={{ color: theme.colors.text.primary }}>{metric.label}</span>
          <span className="font-semibold">{metric.value}</span>
        </div>
      ))}
    </div>
    <div className="mt-4 pt-4 border-t border-gray-200">
      <div className="flex justify-between items-center">
        <span className="text-sm text-text-primary" style={{ color: theme.colors.text.primary }}>Last updated</span>
        <span className="text-sm font-semibold">Today</span>
      </div>
    </div>
  </motion.div>
);

const TestimonialCard = ({ author, role, company, content }) => (
  <motion.div
    className="bg-white p-8 rounded-xl shadow-lg relative"
    initial={{ opacity: 0, x: 20 }}
    whileInView={{ opacity: 1, x: 0 }}
    viewport={{ once: true }}
  >
    <div className="text-primary opacity-20 absolute right-8 top-8" style={{ color: theme.colors.primary }}>
      <FaQuoteRight size={40} />
    </div>
    <p className="text-text-primary mb-6 relative z-10" style={{ color: theme.colors.text.primary }}>"{content}"</p>
    <div className="flex items-center gap-4">
      <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center text-white" style={{ backgroundColor: theme.colors.primary }}>
        {author.split(' ').map(word => word[0]).join('')}
      </div>
      <div>
        <h4 className="font-bold">{author}</h4>
        <p className="text-sm text-text-primary" style={{ color: theme.colors.text.primary }}>{role}, {company}</p>
      </div>
    </div>
  </motion.div>
);

const Home = () => {
  const { isAuthenticated } = useSession();
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  
  const bannerStyle = {
    backgroundImage: 'url("https://images.unsplash.com/photo-1426170042593-200f250dfdaf?q=80&w=2560&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  };

  const testimonials = [
    {
      author: "John Anderson",
      role: "Investment Director",
      company: "Green Capital Partners",
      content: "The detailed analytics and reporting capabilities have transformed how we manage our forest investments. The platform's insights are invaluable."
    },
    {
      author: "Sarah Martinez",
      role: "Sustainability Manager",
      company: "EcoForest Holdings",
      content: "The ESG reporting features are outstanding. It's made tracking and reporting our environmental impact seamless and comprehensive."
    },
    {
      author: "Michael Chang",
      role: "Portfolio Manager",
      company: "Sustainable Assets",
      content: "The due diligence process is thorough and efficient. It's helped us make confident investment decisions backed by solid data."
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="min-h-screen">
      {/* Banner Section */}
      <section className="h-screen flex items-center justify-center relative overflow-hidden" style={bannerStyle}>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-background-dark opacity-30" />
        <motion.div 
          className="absolute inset-0"
          initial={{ backgroundPosition: '0% 0%' }}
          animate={{ backgroundPosition: '100% 100%' }}
          transition={{ duration: 20, repeat: Infinity, repeatType: "reverse" }}
        />
        <div className="glassmorphism p-8 rounded-lg max-w-5xl mx-4 z-10 bg-black bg-opacity-40 backdrop-blur-md border border-white/10">
          <motion.h1 
            className="text-6xl font-bold text-white mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Data-Driven Forest Investing
          </motion.h1>
          <motion.p 
            className="text-xl text-white mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            We drive the future of sustainable investing with smart opportunity analysis and next-generation portfolio reporting.
          </motion.p>
          <motion.div 
            className="flex gap-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <Link to="/due-diligences" className="bg-primary text-white px-8 py-4 rounded-lg hover:bg-primaryDark transition-all duration-300 transform hover:scale-105" style={{ backgroundColor: theme.colors.primary }}>
              Explore Forests
            </Link>
            {!isAuthenticated && (
              <Link to="/login" className="border-2 border-white text-white px-8 py-4 rounded-lg hover:bg-white hover:text-primary transition-all duration-300 transform hover:scale-105">
                Log In
              </Link>
            )}
          </motion.div>
        </div>
      </section>

      {/* Capabilities Section */}
      <Capabilities />

      {/* Investment Opportunities Section */}
      <Section>
        <div className="flex flex-col lg:flex-row items-start gap-12">
          <div className="flex-1">
            <h2 className="text-4xl font-bold text-text-dark mb-4" style={{ color: theme.colors.text.dark }}>Review Investment Opportunities</h2>
            <p className="text-lg text-text-primary mb-6" style={{ color: theme.colors.text.primary }}>Discover thoroughly vetted forest investment opportunities with our comprehensive due diligence process.</p>
            <Link to="/due-diligences" className="bg-primary text-text-light px-6 py-3 rounded-lg hover:bg-primaryDark transition-default inline-block" style={{ backgroundColor: theme.colors.primary, color: theme.colors.text.light }}>Explore Due Diligence</Link>
          </div>
          <div className="w-full lg:w-[400px]">
            <div className="grid grid-cols-1 gap-6">
              <OpportunityCard
                id="sample"
                name="Sample Forest Property"
                imageUrl="https://images.unsplash.com/photo-1542273917363-3b1817f69a2d?q=80&w=2874&auto=format&fit=crop"
                askingPrice={2500000}
                askingPricePerHa={5000}
                propertySizeGIS={500}
                status="Available"
              />
            </div>
          </div>
        </div>
      </Section>

      {/* Sell Your Forest Section */}
      <Section className="bg-background-subtle" style={{ backgroundColor: theme.colors.background.subtle }}>
        <div className="flex flex-col lg:flex-row-reverse items-start gap-12">
          <div className="flex-1">
            <h2 className="text-4xl font-bold text-text-dark mb-4" style={{ color: theme.colors.text.dark }}>Sell Your Forest</h2>
            <p className="text-lg text-text-primary mb-6" style={{ color: theme.colors.text.primary }}>Looking to sell your forest? We offer competitive valuations and a seamless transaction process.</p>
            <Link to="/contact" className="bg-primary text-text-light px-6 py-3 rounded-lg hover:bg-primaryDark transition-default inline-block" style={{ backgroundColor: theme.colors.primary, color: theme.colors.text.light }}>Contact Us</Link>
          </div>
          <div className="flex-1">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <BenefitCard
                icon={FaUserTie}
                title="Expert Valuation"
                description="Get a fair, market-driven valuation from our forest investment experts"
              />
              <BenefitCard
                icon={FaCheckCircle}
                title="Verified Buyers"
                description="Connect with pre-qualified sustainable investors"
              />
              <BenefitCard
                icon={FaChartBar}
                title="Data-Driven Process"
                description="Leverage our advanced analytics for optimal pricing"
              />
              <BenefitCard
                icon={FaShieldAlt}
                title="Secure Transaction"
                description="Experience a safe and transparent selling process"
              />
            </div>
          </div>
        </div>
      </Section>

      {/* Portfolio Reporting Section */}
      <Section>
        <motion.div 
          className="max-w-8xl mx-auto mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-4xl font-bold text-text-dark mb-4" style={{ color: theme.colors.text.dark }}>World-Class Portfolio Reporting</h2>
          <p className="text-lg text-text-primary" style={{ color: theme.colors.text.primary }}>
            Real-time insights and comprehensive analytics for your forest investments
          </p>
        </motion.div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          <ReportingCard
            category="ESG"
            icon={FaLeaf}
            trend={12}
            metrics={[
              { label: "Environmental Score", value: "92/100" },
              { label: "Social Impact", value: "High" },
              { label: "Governance Rating", value: "A+" }
            ]}
          />
          <ReportingCard
            category="Carbon"
            icon={FaChartPie}
            trend={8}
            metrics={[
              { label: "Carbon Sequestered", value: "15K tons" },
              { label: "Annual Growth", value: "+2.3K tons" },
              { label: "Carbon Credits", value: "€180K" }
            ]}
          />
          <ReportingCard
            category="Biodiversity"
            icon={FaGlobeAmericas}
            trend={15}
            metrics={[
              { label: "Species Count", value: "120+" },
              { label: "Habitat Score", value: "95/100" },
              { label: "Conservation Value", value: "Very High" }
            ]}
          />
          <ReportingCard
            category="Financial"
            icon={FaUsers}
            trend={10}
            metrics={[
              { label: "Annual Return", value: "8.5%" },
              { label: "Asset Value", value: "€2.4M" },
              { label: "Growth Rate", value: "12% YoY" }
            ]}
          />
        </div>
      </Section>

      {/* Testimonials Section */}
      <Section className="bg-background-subtle" style={{ backgroundColor: theme.colors.background.subtle }}>
        <motion.div 
          className="max-w-8xl mx-auto mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-4xl font-bold text-text-dark mb-4" style={{ color: theme.colors.text.dark }}>What Our Clients Say</h2>
          <p className="text-lg text-text-primary" style={{ color: theme.colors.text.primary }}>
            Trusted by leading forest investment professionals
          </p>
        </motion.div>
        <div className="max-w-4xl mx-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentTestimonial}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.5 }}
            >
              <TestimonialCard {...testimonials[currentTestimonial]} />
            </motion.div>
          </AnimatePresence>
          <div className="flex justify-center mt-8 gap-2">
            {testimonials.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full transition-all duration-300 ${index === currentTestimonial ? 'bg-primary scale-125' : 'bg-gray-300'}`}
                style={{ backgroundColor: index === currentTestimonial ? theme.colors.primary : undefined }}
                onClick={() => setCurrentTestimonial(index)}
              />
            ))}
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Home;