import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSession } from '@descope/react-sdk';

const OpportunityCard = ({ 
  id, 
  name, 
  imageUrl, 
  askingPrice, 
  askingPricePerHa, 
  propertySizeGIS, 
  status,
  className = '',
  disableLink = false
}) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSession();

  const handleClick = (e) => {
    if (!id || id === 'sample' || id === 'sample-sell') {
      e.preventDefault();
      if (isAuthenticated) {
        navigate('/due-diligences');
      }
    }
  };

  const CardContent = () => (
    <>
      <div
        className="absolute inset-0 bg-cover bg-center transition-transform duration-300 group-hover:scale-105"
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      />
      {/* Dark overlay with gradient */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent" />
      {/* Hover overlay */}
      <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

      <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
        <div className="flex justify-between items-end mb-2">
          <div className="flex-1">
            <h3 className="font-bold text-xl mb-2 text-white drop-shadow-lg">{name}</h3>
            <div className="flex flex-col gap-1">
              <div className="flex items-end">
                <span className="text-sm drop-shadow-lg font-medium text-white">
                  ${askingPrice?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </span>
              </div>
              <div className="flex items-center text-sm">
                <span className="drop-shadow-lg text-white">
                  ${askingPricePerHa?.toLocaleString(undefined, { maximumFractionDigits: 0 })} Ha / {' '}
                  ${(askingPricePerHa * 2.47105)?.toLocaleString(undefined, { maximumFractionDigits: 0 })} Ac
                </span>
              </div>
              <div className="flex items-center text-sm">
                <span className="drop-shadow-lg text-white">
                  {propertySizeGIS?.toLocaleString(undefined, { maximumFractionDigits: 0 })} Ha / {' '}
                  {(propertySizeGIS * 2.47105)?.toLocaleString(undefined, { maximumFractionDigits: 0 })} Ac
                </span>
              </div>
            </div>
          </div>
          <div className="ml-4">
            <span className="text-sm bg-white/30 backdrop-blur-sm px-3 py-1 rounded-full font-medium shadow-lg whitespace-nowrap text-white">
              {status}
            </span>
          </div>
        </div>
      </div>
    </>
  );

  const baseClassName = `group relative min-h-[256px] min-w-[320px] h-64 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 ${className}`;

  return disableLink ? (
    <div className={baseClassName}>
      <CardContent />
    </div>
  ) : (
    <Link to={`/due-diligence/${id}`} onClick={handleClick} className={baseClassName}>
      <CardContent />
    </Link>
  );
};

export default OpportunityCard; 