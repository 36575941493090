import React from 'react';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactInfo = () => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="p-8 max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">Contact Us</h2>
        <p className="text-xl text-gray-600 mb-8">Have questions or want to learn more? Get in touch with our team.</p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className="flex items-start space-x-3">
              <FaEnvelope className="w-5 h-5 text-blue-600 mt-1" />
              <div>
                <p className="font-medium text-gray-900">Email</p>
                <a href="mailto:chris@ovisanalytics.com" className="text-gray-600 hover:text-blue-600 transition-colors">
                  chris@ovisanalytics.com
                </a>
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <FaEnvelope className="w-5 h-5 text-blue-600 mt-1" />
              <div>
                <p className="font-medium text-gray-900">Email</p>
                <a href="mailto:jan@ovisanalytics.com" className="text-gray-600 hover:text-blue-600 transition-colors">
                  jan@ovisanalytics.com
                </a>
              </div>
            </div>
          </div>
          
          <div className="flex items-start space-x-3">
            <FaMapMarkerAlt className="w-5 h-5 text-blue-600 mt-1" />
            <div>
              <p className="font-medium text-gray-900">Address</p>
              <p className="text-gray-600">St. Anna Platz 2,<br />80538 Munich, Germany</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;