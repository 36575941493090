import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';

const markdownComponents = {
  p: ({ children, ...props }) => (
    <p className="text-sm text-gray-700 leading-relaxed mb-4 whitespace-pre-line" { ...props }>
      { children }
    </p>
  ),
  h1: ({ children, ...props }) => (
    <h1 className="text-lg font-semibold mb-3" { ...props }>
      { children }
    </h1>
  ),
  h2: ({ children, ...props }) => (
    <h2 className="text-base font-semibold mb-2" { ...props }>
      { children }
    </h2>
  ),
  h3: ({ children, ...props }) => (
    <h3 className="text-sm font-semibold mb-2" { ...props }>
      { children }
    </h3>
  ),
  ul: ({ children, ...props }) => (
    <ul className="list-disc list-inside mb-4" { ...props }>
      { children }
    </ul>
  ),
  ol: ({ children, ...props }) => (
    <ol className="list-decimal list-inside mb-4" { ...props }>
      { children }
    </ol>
  ),
  li: ({ children, ...props }) => (
    <li className="text-sm text-gray-700 mb-1" { ...props }>
      { children }
    </li>
  ),
  a: ({ children, ...props }) => (
    <a className="text-blue-600 hover:underline break-words" { ...props }>
      { children }
    </a>
  ),
  blockquote: ({ children, ...props }) => (
    <blockquote className="border-l-4 border-gray-200 pl-4 italic my-4" { ...props }>
      { children }
    </blockquote>
  ),
  br: ({ ...props }) => (
    <br className="mb-4" { ...props } />
  ),
};

const MarkdownRenderer = ({ content }) => {
  return (
    <div className="prose prose-sm max-w-none">
      <ReactMarkdown
        remarkPlugins={ [remarkGfm, remarkBreaks] }
        components={ markdownComponents }
      >
        { content }
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownRenderer; 