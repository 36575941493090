import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../../../styles';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { fetchAndParseAirtableRecords } from '../../../services/airtableService';
import LoadingOverlay from '../../../components/BaseComponents/LoadingOverlay';
// Common styled components
const DashboardContainer = styled.div`
  padding: ${theme.common.spacing.lg};
  padding-top: ${theme.common.timeline.container.topSpacing};
  background: transparent;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  margin-bottom: ${theme.common.spacing.xl};
  padding-bottom: ${theme.common.spacing.md};
  border-bottom: 1px solid ${theme.colors.border.light};
`;

const Title = styled.h1`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.sm};
  font-size: 1.8rem;
`;

const GrossProfit = styled.div`
  color: ${props => props.negative ? 'var(--forest-loss)' : 'var(--forest-profit)'};
  font-size: 2rem;
  font-weight: bold;
  font-family: ${theme.fonts.body};
`;

const Overview = styled.div`
  margin: ${theme.common.spacing.xl} 0;
  background: ${theme.colors.background.subtle};
  border-radius: 8px;
  padding: ${theme.common.spacing.lg};
  box-shadow: ${theme.common.shadows.sm};
`;

const SectionTitle = styled.h2`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.lg};
  font-size: 1.2rem;
`;

// Transactions Table Components
const TransactionsTable = styled.div`
  margin: ${theme.common.spacing.xl} 0;
  background: ${theme.colors.background.subtle};
  border-radius: 12px;
  padding: ${theme.common.spacing.lg};
  box-shadow: ${theme.common.shadows.sm};
  overflow: hidden;
`;

const TransactionRow = styled.div`
  display: grid;
  grid-template-columns: 200px 200px minmax(300px, 2fr) minmax(150px, 1fr);
  padding: ${theme.common.spacing.md} ${theme.common.spacing.lg};
  border-bottom: 1px solid ${theme.colors.border.light};
  transition: all ${theme.common.transitions.fast};
  align-items: center;
  min-width: 1000px;
  background: ${props => props.isHeader ? theme.colors.background.muted : 'transparent'};
  
  &:hover {
    background: ${props => props.isHeader ? theme.colors.background.muted : theme.colors.background.muted};
    transform: ${props => props.isHeader ? 'none' : 'translateX(4px)'};
  }

  &:last-child {
    border-bottom: none;
    margin-top: ${theme.common.spacing.sm};
    background: ${theme.colors.background.muted};
    border-radius: 8px;
  }
`;

const CategoryLabel = styled.span`
  color: ${props => props.bold ? theme.colors.text.dark : theme.colors.text.primary};
  font-weight: ${props => props.bold ? '600' : 'normal'};
  font-family: ${theme.fonts.body};
  display: flex;
  align-items: center;
  font-size: ${props => props.bold ? '0.95rem' : '0.9rem'};
  
  /* Make the first column sticky if needed */
  ${props => props.isFirstColumn && `
    position: sticky;
    left: 0;
    background: ${props.isHeader ? theme.colors.background.muted : theme.colors.background.subtle};
    z-index: 2;
    padding-left: ${theme.common.spacing.lg};
  `}
`;

const AmountCell = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Amount = styled.span`
  color: ${props => props.negative ? 'var(--forest-loss)' : 'var(--forest-profit)'};
  font-family: ${theme.fonts.mono};
  font-size: 0.9rem;
  font-weight: ${props => props.bold ? '600' : 'normal'};
  white-space: nowrap;
`;

const CategoryIndicator = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 2px;
  background: ${props => props.color};
  margin-right: ${theme.common.spacing.sm};
`;

// Summary Table Components
const SummaryTableContainer = styled.div`
  margin: ${theme.common.spacing.xl} 0;
  background: ${theme.colors.background.subtle};
  border-radius: 12px;
  padding: ${theme.common.spacing.lg};
  box-shadow: ${theme.common.shadows.sm};
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  overflow-x: auto;
  margin: 0 -${theme.common.spacing.lg};
  padding: 0 ${theme.common.spacing.lg};
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const SummaryTableRow = styled.div`
  display: grid;
  grid-template-columns: 200px minmax(120px, 1fr);
  padding: ${theme.common.spacing.md} ${theme.common.spacing.lg};
  border-bottom: 1px solid ${theme.colors.border.light};
  transition: all ${theme.common.transitions.fast};
  align-items: center;
  min-width: 400px;
  background: ${props => props.isSubtotal ? theme.colors.background.muted : 'transparent'};
  
  &:hover {
    background: ${props => props.isHeader ? 'transparent' : theme.colors.background.muted};
    transform: ${props => props.isHeader ? 'none' : 'translateX(4px)'};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const HeaderRow = styled(SummaryTableRow)`
  background: ${theme.colors.background.muted};
  border-radius: 8px;
  margin-bottom: ${theme.common.spacing.sm};
  font-weight: 600;
  position: sticky;
  left: 0;
`;

const FooterRow = styled(SummaryTableRow)`
  margin-top: ${theme.common.spacing.sm};
  background: ${theme.colors.background.muted};
  border-radius: 8px;
  font-weight: 600;
`;

const SummaryCell = styled.div`
  color: ${props => props.isNegative ? 'var(--forest-loss)' : 'var(--forest-dark)'};
  font-family: ${props => props.isNumeric ? theme.fonts.mono : theme.fonts.body};
  font-size: 0.9rem;
  text-align: ${props => props.isNumeric ? 'right' : 'left'};
  padding-right: ${props => props.isNumeric ? theme.common.spacing.md : '0'};
  font-weight: ${props => props.isSubtotal || props.isHeader || props.isFooter ? '600' : 'normal'};
  
  /* Make the first column sticky */
  ${props => props.isFirstColumn && `
    position: sticky;
    left: 0;
    background: ${props.isHeader || props.isFooter ? theme.colors.background.muted :
      props.isSubtotal ? theme.colors.background.muted :
        theme.colors.background.subtle};
    z-index: 2;
    padding-left: ${theme.common.spacing.lg};
  `}
`;

const formatCurrency = (value) => {
  return `$${Math.abs(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={ {
        background: theme.common.glassmorphism.background,
        backdropFilter: theme.common.glassmorphism.backdropFilter,
        boxShadow: theme.common.glassmorphism.boxShadow,
        padding: theme.common.spacing.md,
        borderRadius: '4px',
        fontFamily: theme.fonts.body
      } }>
        <p style={ {
          color: 'var(--forest-dark)',
          margin: '0 0 4px 0',
          fontWeight: 'bold'
        } }>
          { label }
        </p>
        <p style={ {
          color: payload[0].value < 0 ? 'var(--forest-loss)' : 'var(--forest-profit)',
          margin: '0 0 4px 0',
          fontSize: theme.sizes.medium.fontSize
        } }>
          Transaction: { payload[0].value < 0 ? '-' : '' }CAD ${ Math.abs(payload[0].value).toLocaleString() }
        </p>
        <p style={ {
          color: payload[0].payload.overall < 0 ? 'var(--forest-loss)' : 'var(--forest-profit)',
          margin: 0,
          fontSize: theme.sizes.medium.fontSize
        } }>
          Overall: { payload[0].payload.overall < 0 ? '-' : '' }CAD ${ Math.abs(payload[0].payload.overall).toLocaleString() }
        </p>
      </div>
    );
  }
  return null;
};

const FilterControls = styled.div`
  display: flex;
  gap: ${theme.common.spacing.md};
  margin-bottom: ${theme.common.spacing.lg};
  align-items: center;
  flex-wrap: wrap;
`;

const SearchInput = styled.input`
  padding: ${theme.common.spacing.sm} ${theme.common.spacing.md};
  border: 1px solid ${theme.colors.border.light};
  border-radius: 6px;
  font-family: ${theme.fonts.body};
  font-size: 0.9rem;
  width: 250px;
  background: ${theme.colors.background.muted};
  color: ${theme.colors.text.primary};
  transition: all ${theme.common.transitions.fast};

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary};
    background: ${theme.colors.background.subtle};
  }

  &::placeholder {
    color: ${theme.colors.text.muted};
  }
`;

const Select = styled.select`
  padding: ${theme.common.spacing.sm} ${theme.common.spacing.md};
  border: 1px solid ${theme.colors.border.light};
  border-radius: 6px;
  font-family: ${theme.fonts.body};
  font-size: 0.9rem;
  background: ${theme.colors.background.muted};
  color: ${theme.colors.text.primary};
  cursor: pointer;
  transition: all ${theme.common.transitions.fast};

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary};
    background: ${theme.colors.background.subtle};
  }
`;

const SortButton = styled.button`
  padding: ${theme.common.spacing.sm} ${theme.common.spacing.md};
  border: 1px solid ${theme.colors.border.light};
  border-radius: 6px;
  font-family: ${theme.fonts.body};
  font-size: 0.9rem;
  background: ${theme.colors.background.muted};
  color: ${theme.colors.text.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${theme.common.spacing.sm};
  transition: all ${theme.common.transitions.fast};

  &:hover {
    background: ${theme.colors.background.subtle};
    border-color: ${theme.colors.primary};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

// Add new styled components
const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TitleSection = styled.div``;

const PrintButton = styled.button`
  background: ${theme.colors.primary};
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: ${theme.fonts.body};
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: ${theme.colors.primaryDark};
  }
`;

const DateFilterContainer = styled.div`
  display: flex;
  gap: ${theme.common.spacing.md};
  align-items: center;
  margin-bottom: ${theme.common.spacing.lg};
`;

const StyledDatePicker = styled(DatePicker)`
  padding: ${theme.common.spacing.sm} ${theme.common.spacing.md};
  border: 1px solid ${theme.colors.border.light};
  border-radius: 6px;
  font-family: ${theme.fonts.body};
  font-size: 0.9rem;
  background: ${theme.colors.background.muted};
  color: ${theme.colors.text.primary};
  width: 130px;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary};
    background: ${theme.colors.background.subtle};
  }
`;

const AnnualReview = ({ selectedLandowners = [] }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [startDate, setStartDate] = useState(new Date(2024, 0, 1));
  const [endDate, setEndDate] = useState(new Date(2024, 11, 31));
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasPermission, setHasPermission] = useState(false);

  const filterTransactionsByDate = (transactions) => {
    return transactions.filter(transaction => {
      const transactionDate = new Date(transaction.date);
      return transactionDate >= startDate && transactionDate <= endDate;
    });
  };

  const filteredAndSortedTransactions = React.useMemo(() => {
    return filterTransactionsByDate(transactions)
      .filter(transaction => {
        const matchesSearch = transaction.description.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = selectedCategory === 'all' || transaction.category === selectedCategory;

        const matchesLandowner = selectedLandowners.length === 0 || (
          transaction.landowner && selectedLandowners.some(selected =>
            selected.value === transaction.landowner
          )
        );

        return matchesSearch && matchesCategory && matchesLandowner;
      })
      .sort((a, b) => {
        if (sortConfig.key === 'date') {
          const comparison = new Date(b.date) - new Date(a.date);
          return sortConfig.direction === 'desc' ? comparison : -comparison;
        } else if (sortConfig.key === 'amount') {
          const comparison = b.amount - a.amount;
          return sortConfig.direction === 'desc' ? comparison : -comparison;
        }
        return 0;
      });
  }, [searchTerm, selectedCategory, sortConfig, startDate, endDate, transactions, selectedLandowners]);

  const grossProfit = React.useMemo(() => {
    return filteredAndSortedTransactions.reduce((sum, transaction) => sum + transaction.amount, 0);
  }, [filteredAndSortedTransactions]);

  const filteredChartData = React.useMemo(() => {
    const categoryTotals = filteredAndSortedTransactions.reduce((acc, transaction) => {
      const category = transaction.category;
      acc[category] = (acc[category] || 0) + transaction.amount;
      return acc;
    }, {});

    let runningTotal = 0;
    return Object.entries(categoryTotals)
      .map(([name, amount]) => {
        runningTotal += amount;
        return {
          name,
          amount,
          overall: runningTotal,
          color: transactions.find(t => t.category === name)?.color || 'var(--forest-dark)'
        };
      });
  }, [filteredAndSortedTransactions, transactions]);

  const uniqueCategories = React.useMemo(() => {
    const categories = new Set(filteredChartData.map(item => item.name));
    return ['all', ...Array.from(categories)];
  }, [filteredChartData]);

  const handleSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const baseId = 'appOzTtsysmPCXcnP';
      const tableId = 'tblQAuqWadLwtvY05';

      const data = await fetchAndParseAirtableRecords(baseId, tableId);

      const transformedData = data.map(record => {
        const landowner = Array.isArray(record.Landowner)
          ? record.Landowner[0]
          : record.Landowner;

        return {
          category: record.Category?.[0] || 'Uncategorized',
          description: record.Comment || record['Transaction ID'] || 'No description',
          date: record.Date?.[0] || new Date().toISOString(),
          amount: record.Total || 0,
          color: record.color || 'var(--forest-dark)',
          landowner: landowner
        };
      });

      setTransactions(transformedData);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Check permissions when component mounts
    const checkPermissions = () => {
      const userPermissions = localStorage.getItem('userPermissions');
      if (userPermissions) {
        try {
          setHasPermission(true);
        } catch (error) {
          setHasPermission(false);
        }
      } else {
        setHasPermission(false);
      }
    };
    checkPermissions();
  }, []);

  const SortIcon = ({ direction }) => (
    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      { direction === 'desc' ? (
        <path d="M19 14l-7 7-7-7M19 3l-7 7-7-7" />
      ) : (
        <path d="M19 21l-7-7-7 7M19 10l-7-7-7 7" />
      ) }
    </svg>
  );

  const handlePrint = () => {
    window.print();
  };

  if (!hasPermission) {
    return (
      <DashboardContainer className="flex flex-col items-center justify-center">
        <Header>
          <Title>Access Denied</Title>
        </Header>
        <p>You do not have permission to view this content.</p>
      </DashboardContainer>
    );
  }

  if (isLoading) {
    return <LoadingOverlay message="Loading..." />;
  }

  if (error) {
    return <DashboardContainer>Error: { error }</DashboardContainer>;
  }

  return (
    <DashboardContainer>
      <Header>
        <HeaderContent>
          <TitleSection>
            <Title>Annual Review | Test Forest Holding</Title>
            <GrossProfit negative={ grossProfit < 0 }>
              CAD ${ grossProfit.toLocaleString() }
            </GrossProfit>
          </TitleSection>
          <PrintButton onClick={ handlePrint }>
            <span>🖨️</span> Print
          </PrintButton>
        </HeaderContent>
      </Header>

      <DateFilterContainer>
        <span>Time Period:</span>
        <StyledDatePicker
          selected={ startDate }
          onChange={ date => setStartDate(date) }
          selectsStart
          startDate={ startDate }
          endDate={ endDate }
          dateFormat="MMM dd, yyyy"
        />
        <span>to</span>
        <StyledDatePicker
          selected={ endDate }
          onChange={ date => setEndDate(date) }
          selectsEnd
          startDate={ startDate }
          endDate={ endDate }
          minDate={ startDate }
          dateFormat="MMM dd, yyyy"
        />
      </DateFilterContainer>

      <Overview>
        <SectionTitle>Overview</SectionTitle>
        <div style={ { width: '100%', height: 300 } }>
          <ResponsiveContainer>
            <BarChart
              data={ filteredChartData }
              margin={ { top: 20, right: 30, left: 20, bottom: 5 } }
            >
              <CartesianGrid
                strokeDasharray="3 3"
                stroke={ theme.colors.border.light }
                vertical={ false }
              />
              <XAxis
                dataKey="name"
                stroke={ theme.colors.text.primary }
                tick={ { fill: theme.colors.text.primary, fontSize: 12 } }
                tickLine={ { stroke: theme.colors.border.light } }
              />
              <YAxis
                stroke={ theme.colors.text.primary }
                tick={ { fill: theme.colors.text.primary, fontSize: 12 } }
                tickLine={ { stroke: theme.colors.border.light } }
                tickFormatter={ (value) => `$${value.toLocaleString()}` }
              />
              <Tooltip content={ <CustomTooltip /> } />
              <Bar
                dataKey="amount"
                name="Transaction"
                radius={ [4, 4, 0, 0] }
              >
                { filteredChartData.map((entry, index) => (
                  <Cell key={ `cell-${index}` } fill={ entry.color } />
                )) }
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Overview>

      <SummaryTableContainer>
        <SectionTitle>Summary</SectionTitle>
        <ScrollContainer>
          <HeaderRow>
            <SummaryCell isFirstColumn isHeader>Category</SummaryCell>
            <SummaryCell isNumeric isHeader>2024</SummaryCell>
          </HeaderRow>

          {/* Harvesting Section */ }
          <SummaryTableRow>
            <SummaryCell isFirstColumn>Tonnes SW</SummaryCell>
            <SummaryCell isNumeric>9,754</SummaryCell>
          </SummaryTableRow>
          <SummaryTableRow>
            <SummaryCell isFirstColumn>Tonnes HW</SummaryCell>
            <SummaryCell isNumeric>12,835</SummaryCell>
          </SummaryTableRow>
          <SummaryTableRow isSubtotal>
            <SummaryCell isFirstColumn isSubtotal>Tonnes Total</SummaryCell>
            <SummaryCell isNumeric isSubtotal>22,588</SummaryCell>
          </SummaryTableRow>

          {/* Stumpage Section */ }
          <SummaryTableRow>
            <SummaryCell isFirstColumn>Av. Stumpage SW</SummaryCell>
            <SummaryCell isNumeric>{ formatCurrency(26.22) }</SummaryCell>
          </SummaryTableRow>
          <SummaryTableRow>
            <SummaryCell isFirstColumn>Av. Stumpage HW</SummaryCell>
            <SummaryCell isNumeric>{ formatCurrency(9.27) }</SummaryCell>
          </SummaryTableRow>
          <SummaryTableRow isSubtotal>
            <SummaryCell isFirstColumn isSubtotal>Av. Stumpage</SummaryCell>
            <SummaryCell isNumeric isSubtotal>{ formatCurrency(16.59) }</SummaryCell>
          </SummaryTableRow>

          {/* Sales Section */ }
          <SummaryTableRow>
            <SummaryCell isFirstColumn>Stumpage Sales SW</SummaryCell>
            <SummaryCell isNumeric>{ formatCurrency(255732.08) }</SummaryCell>
          </SummaryTableRow>
          <SummaryTableRow>
            <SummaryCell isFirstColumn>Stumpage Sales HW</SummaryCell>
            <SummaryCell isNumeric>{ formatCurrency(118942.59) }</SummaryCell>
          </SummaryTableRow>
          <SummaryTableRow isSubtotal>
            <SummaryCell isFirstColumn isSubtotal>Stumpage Sales Total</SummaryCell>
            <SummaryCell isNumeric isSubtotal>{ formatCurrency(374674.67) }</SummaryCell>
          </SummaryTableRow>

          {/* Funding Section */ }
          <SummaryTableRow isSubtotal>
            <SummaryCell isFirstColumn isSubtotal>Funding</SummaryCell>
            <SummaryCell isNumeric isSubtotal>{ formatCurrency(488546.90) }</SummaryCell>
          </SummaryTableRow>

          {/* Costs Section */ }
          <SummaryTableRow isSubtotal>
            <SummaryCell isFirstColumn isSubtotal>Road Construction</SummaryCell>
            <SummaryCell isNumeric isSubtotal>{ formatCurrency(0) }</SummaryCell>
          </SummaryTableRow>
          <SummaryTableRow isSubtotal>
            <SummaryCell isFirstColumn isSubtotal>Silviculture</SummaryCell>
            <SummaryCell isNumeric isSubtotal>{ formatCurrency(488546.90) }</SummaryCell>
          </SummaryTableRow>
          <SummaryTableRow isSubtotal>
            <SummaryCell isFirstColumn isSubtotal>Costs Total</SummaryCell>
            <SummaryCell isNumeric isNegative isSubtotal>{ formatCurrency(119192.00) }</SummaryCell>
          </SummaryTableRow>
          <SummaryTableRow>
            <SummaryCell isFirstColumn>Costs per Tonne</SummaryCell>
            <SummaryCell isNumeric isNegative>{ formatCurrency(5.28) }</SummaryCell>
          </SummaryTableRow>

          {/* Profit Section */ }
          <FooterRow>
            <SummaryCell isFirstColumn isFooter>Gross Profit</SummaryCell>
            <SummaryCell isNumeric isNegative={ 249550.67 < 0 } isFooter>{ formatCurrency(249550.67) }</SummaryCell>
          </FooterRow>
        </ScrollContainer>
      </SummaryTableContainer>

      <TransactionsTable>
        <SectionTitle>Detailed Transactions</SectionTitle>
        <ScrollContainer>
          <FilterControls>
            <SearchInput
              type="text"
              placeholder="Search descriptions..."
              value={ searchTerm }
              onChange={ (e) => setSearchTerm(e.target.value) }
            />
            <Select
              value={ selectedCategory }
              onChange={ (e) => setSelectedCategory(e.target.value) }
            >
              { uniqueCategories.map(category => (
                <option key={ category } value={ category }>
                  { category === 'all' ? 'All Categories' : category }
                </option>
              )) }
            </Select>
            <SortButton onClick={ () => handleSort('date') }>
              Date
              <SortIcon direction={ sortConfig.key === 'date' ? sortConfig.direction : 'desc' } />
            </SortButton>
            <SortButton onClick={ () => handleSort('amount') }>
              Amount
              <SortIcon direction={ sortConfig.key === 'amount' ? sortConfig.direction : 'desc' } />
            </SortButton>
          </FilterControls>

          <TransactionRow isHeader>
            <CategoryLabel bold>Date</CategoryLabel>
            <CategoryLabel bold>Category</CategoryLabel>
            <CategoryLabel bold>Description</CategoryLabel>
            <AmountCell>
              <CategoryLabel bold>Amount</CategoryLabel>
            </AmountCell>
          </TransactionRow>
          { filteredAndSortedTransactions.map((transaction, index) => (
            <TransactionRow key={ index }>
              <CategoryLabel>
                { new Date(transaction.date).toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }) }
              </CategoryLabel>
              <CategoryLabel>
                <CategoryIndicator color={ transaction.color } />
                { transaction.category }
              </CategoryLabel>
              <CategoryLabel>{ transaction.description }</CategoryLabel>
              <AmountCell>
                <Amount negative={ transaction.amount < 0 }>
                  { transaction.amount < 0 ? '-' : '' }CAD ${ Math.abs(transaction.amount).toLocaleString() }
                </Amount>
              </AmountCell>
            </TransactionRow>
          )) }
          <TransactionRow>
            <CategoryLabel bold>Total</CategoryLabel>
            <CategoryLabel></CategoryLabel>
            <CategoryLabel></CategoryLabel>
            <AmountCell>
              <Amount negative={ grossProfit < 0 } bold>
                { grossProfit < 0 ? '-' : '' }CAD ${ Math.abs(grossProfit).toLocaleString() }
              </Amount>
            </AmountCell>
          </TransactionRow>
        </ScrollContainer>
      </TransactionsTable>
    </DashboardContainer>
  );
};

export default AnnualReview;
