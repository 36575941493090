import React from 'react';
import Select from 'react-select';
import { theme } from '../../../styles';

const TimelineFilter = ({ categories, selectedCategories, onChange }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: 'rgba(255, 255, 255, 0.8)',
      backdropFilter: 'blur(10px)',
      borderRadius: theme.common.borderRadius,
      border: '1px solid rgba(229, 231, 235, 0.5)',
      boxShadow: theme.common.glassmorphism.boxShadow,
      '&:hover': {
        border: '1px solid rgba(229, 231, 235, 0.8)'
      },
      width: '300px',
    }),
    menu: (base) => ({
      ...base,
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '0.25rem',
      border: '1px solid rgba(229, 231, 235, 0.5)',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? theme.colors.primary.light
        : state.isFocused
          ? 'rgba(229, 231, 235, 0.3)'
          : 'transparent',
      color: state.isSelected ? theme.colors.primary.dark : theme.colors.text.primary,
      '&:hover': {
        backgroundColor: 'rgba(229, 231, 235, 0.3)'
      }
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: theme.colors.primary.light,
      borderRadius: '0.25rem'
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: theme.colors.primary.dark
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: theme.colors.primary.dark,
      '&:hover': {
        backgroundColor: theme.colors.primary.main,
        color: 'white'
      }
    })
  };

  return (
    <div className="z-50 max-w-md">
      <Select
        isMulti
        options={ categories.map(category => ({
          value: category,
          label: category
        })) }
        value={ selectedCategories.map(category => ({
          value: category,
          label: category
        })) }
        onChange={ (selected) => {
          onChange(selected ? selected.map(option => option.value) : []);
        } }
        styles={ customStyles }
        placeholder="Filter by category..."
        className="w-full"
        classNamePrefix="timeline-filter"
      />
    </div>
  );
};

export default TimelineFilter;